@import url('https://fonts.googleapis.com/css?family=Open+Sans');

$backgroundColor: #fafafb;

html {
    height: 100%;
}
.page {
    min-height: calc(100vh - 56px - 56px);
}

body {
    position: relative;
    margin: 0;
    padding: 0;
    padding-top: 80px;
    /*padding-bottom: 56px;*/
    min-height: 100%;
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
    background-color: $backgroundColor;
    background-position: top;
    background-attachment: fixed;
}

.page {
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

body > header {
    .navbar {
        padding: 0;
        background: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.25) 0 0 6px 0;
    }
    .navbar-brand {
        img {
            max-height: 70px;
        }
    }
}
.slider {
    border: 1px solid red;
    height: 480px;
}

footer {
    padding: 1rem;
    /*
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    color: #FFFFFF;
    background-color: #efefef;
    text-align: center;
    z-index: 9999;
    */
}
/* Footer navigation */
footer {
    .nav {
        .nav-link {
            padding: 0 1rem;
            color: rgba(255, 255, 255, 0.7);
        }
        .active,
        .open {
            > .nav-link {
                color: #ffffff;
                //background-color: #b4df5b;
                //background: linear-gradient(to bottom, #b4df5b 0%, #b4df5b 93%, #66b6fc 93%, #66b6fc 100%);
                box-shadow: none;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    nav {
        .scrollable-menu {
            height: auto;
            max-height: calc(100vh - 56px);
            overflow-x: hidden;
        }
    }
}

.featured {
    margin-bottom: 20px;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 20px;
        padding: 0;
        width: 1.9em;
        height: 1.9em;
        background-color: #404040;
        color: #ffffff;
        font-size: 3em;
        border-radius: 12px;
    }
    .content {
        h5 {
            padding-bottom: 5px;
            border-bottom: 1px solid lightgrey;
        }
        ul {
            text-align: left;
        }
    }
}

.gallery {
    img {
        max-width: 100%
    }
}

.contact-links a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
