@import '~animate.css/animate.min.css';
@import '~bootstrap/scss/bootstrap';

@import '~@fancyapps/fancybox/dist/jquery.fancybox.css';

@import 'slick';

@import 'mixins';
@import 'global';
@import 'fancybox';
@import 'leaflet';

@import 'cookie-consent';
