@import '~cookieconsent/build/cookieconsent.min.css';

.cc-window,
.cc-revoke {
    z-index: 99999;
}
.cc-window {
    .cc-btn {
        outline: none;
    }
    .cc-btn.cc-deny:first-child:hover {
        background-color: rgb(65, 74, 84);
    }
    .cc-btn:first-child:hover,
    .cc-btn:hover {
        text-decoration: none;
    }
}
